import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

//components
import Root from "./Root";

//home
const Home = React.lazy(() => import("../pages/index"));

//privacy policy
const PrivacyPolicy = React.lazy(() => import("../pages/privacy-policy"));

//terms and conditions
const TermsAndConditions = React.lazy(() =>
  import("../pages/terms-and-conditions")
);

//terms and conditions
const ResetPassword = React.lazy(() => import("../pages/reset-password"));

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => {
  return (
    <Suspense fallback={loading()}>
      <Component />
    </Suspense>
  );
};

const AllRoutes = () => {
  return useRoutes([
    {
      //root route
      path: "/",
      element: <Root />,
    },
    {
      //public routes
      path: "/",
      children: [
        {
          path: "/home",
          element: <LoadComponent component={Home} />,
        },
        {
          path: "privacy-policy",
          element: <LoadComponent component={PrivacyPolicy} />,
        },
        {
          path: "terms-and-conditions",
          element: <LoadComponent component={TermsAndConditions} />,
        },
        {
          path: "reset-password",
          element: <LoadComponent component={ResetPassword} />,
        },
      ],
    },
  ]);
};

export default AllRoutes;
